<!--prettier-ignore-->
<template>
  <main class="bg-gray-50 dark:bg-gray-900">
    <div class="flex flex-col justify-center items-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
      <a href="../../" class="flex justify-center items-center mb-8 text-2xl font-semibold lg:mb-10 dark:text-white">
          <img src="~/assets/images/logo.svg" class="mr-4 h-11" alt="FlowBite Logo">
          <span>Mindlever</span>  
      </a>
      <!-- Card -->
      <div class="justify-center items-center w-full bg-white rounded-lg shadow lg:flex md:mt-0 lg:max-w-screen-lg 2xl:max:max-w-screen-lg xl:p-0 dark:bg-gray-800">
          <div class="hidden w-2/3 lg:flex">
              <img class="rounded-l-lg" src="~/assets/images/login.jpg" alt="login image">
          </div>
          <div class="p-6 space-y-8 w-full sm:p-8 lg:p-16 lg:py-0">
              <h2 class="text-2xl font-bold text-gray-900 lg:text-3xl dark:text-white">
                  Sign in to platform 
              </h2>
              <form class="mt-8 space-y-6" @submit.prevent="handleLogin">
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                      <input id="email" v-model="email" type="email" name="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="name@email.com" required>
                  </div>
                  <div>
                      <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
                      <input id="password" v-model="password" type="password" name="password"  placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required>
                  </div>
                  <div class="flex items-start">
                      <div v-if="false" class="flex items-center h-5">
                          <input  id="remember" aria-describedby="remember" name="remember" type="checkbox" class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600">
                      </div>
                      <div v-if="false" class="ml-3 text-sm">
                      <label for="remember" class="font-medium text-gray-900 dark:text-white">Remember me</label>
                      </div>
                      <NuxtLink to="/forgot-password" class="ml-auto text-sm text-primary-700 hover:underline dark:text-primary-500">Lost Password?</NuxtLink>
                  </div>
                  <button type="submit" class="py-3 px-5 w-full text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Login to your account</button>
                  <div v-if="false" class="text-sm font-medium text-gray-500 dark:text-gray-400">
                      Not registered? <a class="text-primary-700 hover:underline dark:text-primary-500">Create account</a>
                  </div>
              </form>
          </div>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { initFlowbite } from "flowbite";

// initialize components based on data attribute selectors
onMounted(() => {
  initFlowbite();
});

useHead({
  htmlAttrs: {
    class: "dark:bg-gray-900",
  },
  bodyAttrs: {
    class: "dark:bg-gray-900",
  },
});

const supabase = useSupabaseClient();
const user = useSupabaseUser();

watchEffect(() => {
  if (user.value) {
    nextTick(async () => {
      await navigateTo("/");
    });
  }
});

const loading = ref(false);
const email = ref("");
const password = ref("");

const handleLogin = async () => {
  try {
    loading.value = true;
    const { error } = await supabase.auth.signInWithPassword({
      email: email.value,
      password: password.value,
    });
    if (error) throw error;
  } catch (error: any) {
    alert(error.error_description || error.message);
  } finally {
    loading.value = false;
  }
};
</script>
